<template>
    <div class="dark:bg-cyan-900 bg-sky-200">
     <Header></Header>
      <slot name="default" >

      </slot>
      <Footer></Footer>
    </div>
  </template>
  <script>
  export default {
    head: {
      bodyAttrs: {
        class: 'font-sans flex lg:justify-center bg-white text-gray-300 dark:text-teal-50'
      }
    }
  }
</script>