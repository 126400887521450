<template>
    <nav class="bg-gray-800" >
      <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div class="relative flex h-16 items-center justify-between">
          <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <!-- Mobile menu button-->
            <button class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span class="sr-only">Open main menu</span>
              <!-- <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" /> -->
            </button>
          </div>
          <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div class="flex flex-shrink-0 items-center">
              <NuxtLink to="/">
              <img class="block h-8 w-auto lg:hidden" src="/img/g49633-dark.png" alt="Dr. Wordpress" />
              <img class="hidden h-8 w-auto lg:block" src="/img/g49633-dark.png" alt="Dr. Wordpress" />
              </NuxtLink>
            </div>
            <div class="hidden sm:ml-6 sm:flex sm:justify-center">
              <div class="flex space-x-4">
                <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'rounded-md px-3 py-2 text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
              </div>
            </div>
          </div>
          <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <button type="button" class="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
              <span class="sr-only">View notifications</span>
              <!-- <BellIcon class="h-6 w-6" aria-hidden="true" /> -->
            </button>
  
            
          </div>
        </div>
      </div>
  
      <div class="sm:hidden">
        <div class="space-y-1 px-2 pb-3 pt-2">
          <button v-for="item in navigation" :key="item.name" as="a" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</button>
        </div>
      </div>
    </nav>
  </template>
  
  <script setup>
//   import { Disclosure, button, div, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
//   import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'
  
  const navigation = [
    // { name: 'Wordpress Reparieren', href: '#', current: false },
    // { name: 'Audit', href: '#', current: false },
    // { name: 'Wordpress Migrieren', href: '#', current: false },
    { name: 'Blog', href: '/', current: true },
  ]
  </script>